<template>
	<div :class="themeClass">
		<div class="margin-t-10  width-fill bg-fff ">
			<div class="collect-content-box">
				<div class="u-my-mesText font-size18 font-weight700">
					<div class="my-invoice-text">我的收藏</div>
				</div>
				<div class="flex-row  flex-wrap">
					<div class=" collect-item-content position-relative cursor-pointer" v-for="(item,index) in collectData" :key="index">
						<span @click.stop="Delete(item)" class="iconfont icon-ashbin position-absolute delete-btn pointer"></span>
						<div class="flex-row collect-item">
							<div class="img-box" @click="gotoGoodDetil(item.fGoodsID)">
								<img :src="item.fPicturePath" alt="">
							</div>
							<div class="right-item-box hide-text">
								<span class="font-size14" @click="gotoGoodDetil(item.fGoodsID)">{{item.fGoodsFullName}}</span>
								<div class="font-size12 font-color-FF0000" @click="gotoGoodDetil(item.fGoodsID)">
									<span class="font-weight700  font-size16">
										{{item.fGoodsPrice}}
									</span>元/吨
								</div>
								<div class="margin-t-6" v-if="item.fShopTypeName != '非自营'" @click="gotoGoodDetil(item.fGoodsID)">
									<span class="tag-box text-center radius2 font-color-fff font-size12 margin-r-5">
										{{item.fShopTypeName}}
									</span>
									<!-- <span class="u_multiplel_tintegral font-size12 font-color-FF0000 text-center radius2 border-FF0000">
											{{item.goodtintegral}}倍积分 
									</span> -->
								</div>
								<div class="u_shopNmae_1 hide-text" @click="goToShop(item)">
									<span class=" font-color-ccc font-size12 shop-namebox hide-text" style="width:95px;;line-height:10px;">
										{{ item.fShopName}}
									</span>
									<span class=" font-size12 pointer">
										进店
									</span>
									<span class="iconfont icon-youkuohao font-size11 font-color-1e1e1e">
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex-row-center-center" v-if="collectData.length == 0">
					<svg-icon icon-class="NoCollection" />
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				pagesize: 12,
				total: 10, //总数据
				currentPage: 1, //当前页
				collectData: []
			}
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.MyAttention()
		},
		methods: {
			//我的收藏
			MyAttention() {
				this.ApiRequestPost('api/mall/ebsale/goods-price/get-building-material-by-auxiliary', {
					maxResultCount: this.pagesize, //每页显示10条
					skipCount: (this.currentPage - 1) * this.pagesize, //当前页显示数据（跳过多少条数据）
					filter: '', //模糊查询
					sorting: '',
				}).then(
					res => {
						this.$nextTick(() => {
							console.log(res, "resresresresresresres")
							this.collectData = res.obj.items;
							console.log(this.collectData, "this.listData")
						});
					},
					error => {
						this.$message({
							type: 'success',
							message: 'error.message'
						});
					}
				);
			},

			gotoGoodDetil(id) {
				this.$router.push({
					name: 'GoodsDetails',
					query: {
						fGoodsID: id
					}
				})
				console.log(this.fGoodsID, "this.fGoodsID", )
			},


			Delete(item) {
				this.$confirm('取消收藏商品, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params = [];
					let data = {
						fUserCollectionID: item.fUserCollectionID,
						frv: item.frv,
						rowStatus: 8
					};
					params.push(data);
					this.ApiRequestPost('/api/mall/auxiliary/goods-collect/delete-list', params).then(
						result => {
							this.MyAttention();
						},
						rej => {}
					);
				}).catch(() => {
					//this.$message({type: 'info',message: '已取消删除',showClose: false,});          
				});
			},
			goToShop(item) {
				console.log(item);
				this.$router.push({
					name: 'storeList',
					query: {
						id: item.fShopUnitID
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.collect-content-box {
		min-height: 580px;
		padding: 7px 13px 13px 13px;
	}

	// .page-box{
	// 	// width: 1100px;
	// 	// overflow: hidden;
	// }
	.flex-wrap {
		flex-wrap: wrap;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.margin-t-6 {
		margin-top: 6px;
	}

	.my-invoice-text {
		width: 903px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.collect-item-content {
		width: 282px;
		height: 110px;
		margin-right: 13px;
		margin-top: 20px;
		background: #ffffff;
		border: 1px solid #f2f2f2;
		border-radius: 10px;

		.collect-item {
			margin: 11px;

			.right-item-box {
				width: 139px;
				margin-left: 8px;
			}

		}
	}

	.img-box img {
		width: 86px;
		height: 86px;
		background: #f2f4fd;
		border-radius: 5px;

	}

	.delete-btn {
		right: 5px;
		top: 0;
		font-size: 24px;
		color: #cacaca;
	}

	.tag-box {
		width: 30px;
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.u_shopNmae_1 {

		// line-height: 25px;
		.shop-namebox {
			display: inline-block;
		}
	}
</style>
